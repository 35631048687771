import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import Language from "../Language"

import { SectionTopbar, 
         ContainerTopbar, 
         ItemTopbar,
         LinkToCall,
         IconPhone,
         IconEmail,
         IconCalender } from "./styles"

const TopBar = () => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            contato: prismicContacts { 
               data {
                  pabx1 { text }
                  email_vendas { text }
                  email_tecnico { text }
               }
            }
         }
      `
   )
  
   return (
      <SectionTopbar>
         <ContainerTopbar>
            <ItemTopbar>
               <IconPhone />
               <LinkToCall href={`tel:${content.contato.data.pabx1.text}`}>
                  {content.contato.data.pabx1.text}
               </LinkToCall>
            </ItemTopbar>
            <ItemTopbar>
               <IconEmail />
               {content.contato.data.email_vendas.text}
            </ItemTopbar>
            <ItemTopbar>
               <IconEmail />
               {content.contato.data.email_tecnico.text}
            </ItemTopbar>
            <ItemTopbar>
               <IconCalender />
               {intl.formatMessage({ id: "expediente.title" })}&nbsp;
               {intl.formatMessage({ id: "expediente.close_holiday" }).replace(/Qui|Dom|Thu|Sun|Jue/gi,"").replace("2024","24").replace("2025","25")}
            </ItemTopbar>
            <ItemTopbar>
               <Language />
            </ItemTopbar>
         </ContainerTopbar>
      </SectionTopbar>
   )
}

export default TopBar

/* USAR PARA DUAS DATAS (PERÍODO PROLONGADO) */
/* {intl.formatMessage({ id: "expediente.close_holiday" }).replace(/Qui|Dom|Thu|Sun|Jue/gi,"").replace("2020","20").replace("2021","21")} */

/* USAR PARA DATAS ÚNICA */
/* {intl.formatMessage({ id: "expediente.close_holiday" })} */

/* OU EM CASOS ESPECIAIS */
/* Digite na mão sem usar Intl */